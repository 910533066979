import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "120",
  height: "120",
  fill: "currentColor",
  viewBox: "0 0 640 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M48 32C21.5 32 0 53.5 0 80v256c0 26.5 21.5 48 48 48h17.1c7.8-54.3 54.4-96 110.9-96s103.1 41.7 110.9 96H608c17.7 0 32-14.3 32-32s-14.3-32-32-32h-64V80c0-26.5-21.5-48-48-48zm32 64c8.8 0 16 7.2 16 16v131.2c-11.4 5.9-22.2 12.9-32 21V112c0-8.8 7.2-16 16-16m96 128c-5.4 0-10.7.2-16 .7V112c0-8.8 7.2-16 16-16s16 7.2 16 16v112.7c-5.3-.5-10.6-.7-16-.7m80 19.2V112c0-8.8 7.2-16 16-16s16 7.2 16 16v152.2c-9.8-8.1-20.6-15.2-32-21M368 96c8.8 0 16 7.2 16 16v192c0 8.8-7.2 16-16 16s-16-7.2-16-16V112c0-8.8 7.2-16 16-16m112 16v192c0 8.8-7.2 16-16 16s-16-7.2-16-16V112c0-8.8 7.2-16 16-16s16 7.2 16 16M176 480a80 80 0 0 0 56.569-23.431 80.005 80.005 0 0 0 0-113.138 80.005 80.005 0 0 0-113.138 0 80.005 80.005 0 0 0 0 113.138A80 80 0 0 0 176 480m0-112a32 32 0 0 1 22.627 9.373 31.996 31.996 0 0 1 0 45.254 31.996 31.996 0 0 1-45.254 0 31.996 31.996 0 0 1 0-45.254A32 32 0 0 1 176 368" }, null, -1)
  ])))
}
export default { render: render }